import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  url;
  chatMessagesUpdated = new Subject();
  promptChatMessagesUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private helpersService: HelpersService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
    // this.url = 'https://backend-int.linget.it'
  }

  translateToPolishWithChat(message) {
    this.http
      .post<{ sets: any }>(
        this.url + '/api/chat/translate-to-polish-with-chat',
        {
          message: message,
        },
      )
      .pipe()
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: chat.service.ts:44 ~ ChatService ~ .subscribe ~ response',
          response,
        );
        const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;
        console.log(
          '🚀 ~ file: chat.service.ts:35 ~ ChatService ~ .subscribe ~ koszt:',
          costPLN + ' groszy',
        );
        this.chatMessagesUpdated.next(response);
      });
  }
  sendMessage(message: string, file: File | null): Observable<string> {
    const formData = new FormData();
    formData.append('message', message);
    if (file) {
      formData.append('file', file, file.name);
    }

    return this.http.post<string>(this.url + '/api/chat/send-chat', formData);
  }


  sendPromptToChosenAi(data: {
    messages,
    aiProvider: string,
    model: string,
    maxTokens?: number,
    maxMessages?: number,
    systemMsg?: string,
    files?
  }): Observable<any> {
    const msgs = this.helpersService.clone(data.messages)
    const messagesToSend = msgs.map((message) => {
      return {
        role: message.role,
        content: message.content,
      };
    });
    const formData = new FormData();
    formData.append('messages', JSON.stringify(messagesToSend));
    formData.append('aiProvider', data.aiProvider);
    formData.append('model', data.model);
    if (data.maxTokens) {
      formData.append('maxTokens', data.maxTokens.toString());
    }
    if (data.maxMessages) {
      formData.append('maxMessages', data.maxMessages.toString());
    }
    if (data.systemMsg) {
      formData.append('systemMsg', data.systemMsg);
    }

    console.log("🚀 ~ ChatService ~ data:", data)
    if (data.files && data.files.length > 0) {
      Array.from(data.files).forEach((file: any, index) => {
        formData.append(`files`, file, file.name);
      });
    }

    return this.http.post(this.url + '/api/chat/send-prompt-to-chosen-ai', formData)
      .pipe(
        tap(response => {
          console.log('Response:', response);
        })
      );
  }


  sendPrompt(prompt, model?, jsonOutput?) {
    this.http
      .post<{ sets: any }>(
        this.url + '/api/chat/send-prompt',
        {
          message: prompt + ' Dont talk to me only respond to my prompt.',
          model: model,
          jsonOutput: jsonOutput,
        },
      )
      .pipe()
      .subscribe((response: any) => {

        this.promptChatMessagesUpdated.next(response);
      });
  }
  correctPolishText(message) {
    this.http
      .post<{ sets: any }>(this.url + '/api/chat/correct-polish-text', {
        message: message,
      })
      .pipe()
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: chat.service.ts:44 ~ ChatService ~ .subscribe ~ response',
          response,
        );
        const costPLN = response.res.usage.total_tokens * 0.000002 * 4.2 * 100;
        console.log(
          '🚀 ~ file: chat.service.ts:35 ~ ChatService ~ .subscribe ~ koszt:',
          costPLN + ' groszy',
        );
        this.chatMessagesUpdated.next(response);
      });
  }
  chatMessagesListener() {
    return this.chatMessagesUpdated.asObservable();
  }
  promptChatMessagesListener() {
    return this.promptChatMessagesUpdated.asObservable();
  }
}
