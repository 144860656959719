import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class SendSmsComponent implements OnInit {
  messages = [
    {
      message: 'Dzien dobry, przypominamy ze zgodnie z rocznym harmonogramem, w ferie nie ma zajec. Wracamy do nauki 26.02. Do zobaczenia po przerwie:) Pozdrawiamy, Ling King',
      displayName: 'Ferie zimowe'
    },
    {
      message: 'Dzien dobry, Przypominamy, ze od srody 27.03 do wtorku 02.04 nie ma zajec (oprocz ustalonego odrabiania). Wracamy w srode 03.04. Wesolych Swiat! - Ling King',
      displayName: 'Wielkanoc'
    },
    {
      message: 'Dzien dobry, Przypominamy, ze od srody 01.05 do wtorku 07.05 nie ma zajec. Wracamy w srode 08.05. Zyczymy udanej Majowki! - Ling King',
      displayName: 'Majowka'
    },
    {
      message: 'Dzien dobry, Przypominamy, ze od czwartku 30.05 do piatku 31.05 nie ma zajec. Wracamy w poniedzialek 03.06. Zyczymy udanej przerwy! - Ling King',
      displayName: 'Dlugi weekend'
    },
    {
      message: 'Dzien dobry, Przypominamy, ze zgodnie z rocznym harmonogramem dolaczonym do umowy, od najblizszej srody (30.10) do piatku (01.11) zajęcia się nie odbywaja. Do zobaczenia w poniedzialek 04.11. Nie dotyczy to odrabiania zajec, ktore zostalo indywidualnie ustalone. Pozdrawiamy, Ling King',
      displayName: 'Wszystkich Swietych'
    },


  ]
  selectedMessage
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    private emailService: EmailService
  ) { }

  ngOnInit() {
    // asd
    console.log(this.data)
  }
  sendSMS() {
    if (this.selectedMessage?.length > 0) {
      if (confirm('Czy na pewno chcesz wysłać SMS?')) {
        let allCustomers = [];
        this.data.groups.forEach(group => {
          allCustomers = allCustomers.concat(group.students.map(student => {
            return {
              name: student.customer,
              phone: student.phone, selected: student.selected
            };
          }));
        });
        console.log("🚀 ~ SendSmsComponent ~ sendSMS ~ allCustomers:", allCustomers);
        let filteredCustomers = allCustomers.filter((customer, index, self) =>
          index === self.findIndex((t) => (
            t.phone === customer.phone
          ))
        );
        filteredCustomers = filteredCustomers.filter(customer => customer.phone !== 111222333 && customer.phone !== '111222333' && customer.phone.length > 5 && customer.selected);
        console.log("🚀 ~ SendSmsComponent ~ sendSMS ~ filteredCustomers", filteredCustomers);
        let phonesToSend = filteredCustomers.map(customer => parseInt(customer.phone.replace(/-/g, '')));
        console.log("🚀 ~ SendSmsComponent ~ sendSMS ~ phonesToSend:", phonesToSend);
        this.emailService.sendSMSToCustomers(phonesToSend, this.selectedMessage);
      }
    }
    else {
      alert('Wybierz wiadomość')
    }
  }
}

