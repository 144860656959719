import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { UsersServiceService } from "../../../../shared/services/users-service.service";
import { Subscription } from "rxjs";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { UserDetails } from '../../../../shared/services/authentication.service';
import { UserDetailsService } from '../../../user-details.service';

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  status: string;
}

const users: User[] = [];

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class UsersListComponent implements OnInit {
  users = [];
  usersPhoneToSet
  roles = ["student", "teacher", "customer"];
  statuses = ["active", "inactive", "locked"];
  editMode = false;
  index;
  @ViewChild(MatPaginator, {
    static: true,
  })
  paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: true,
  })
  sort: MatSort;
  displayedColumns: string[] = ["name", "email", "role", "status", "actions"];
  dataSource = new MatTableDataSource<User>(this.users);
  usersSub: Subscription;
  isTableExpanded = false;
  hover = false;
  newUserIdToAdd: any;

  constructor(
    private usersService: UsersServiceService,
    private authService: AuthenticationService, public userDetails: UserDetailsService
  ) { }

  ngOnInit(): void {
    // this.usersService.fetchUsers(1, 10);
    if (this.userDetails.owner) {
      this.roles.push("owner")
    }
    this.usersSub = this.usersService
      .userUpdatedListener()
      .subscribe((users) => {
        this.users = users;
        this.dataSource = new MatTableDataSource<User>(this.users);
        this.dataSource.sort = this.sort;
      });

    // Subscribe to the page event
    // this.paginator.page.subscribe((pageEvent: PageEvent) => {
    //   // Call your service with the updated page information
    //   this.usersService.fetchUsers(pageEvent.pageIndex, pageEvent.pageSize);
    // });
  }
  addNewStudentId(i, user) {
    this.usersService.addNewStudentsAccountsIds(user.id, this.newUserIdToAdd);
  }
  setUsersPhone(i, user) {
    this.usersService.setUsersPhone(user.id, this.usersPhoneToSet);
  }

  onEditUserClick(i, user) {
    this.editMode = true;
    this.index = i;
    console.log("i: ", i);
  }
  resetPasswordWithTemp(user) {
    console.log(
      "🚀 ~ file: users.component.ts ~ line 37 ~ UsersComponent ~ resetPasswordWithTemp ~ user",
      user
    );
    this.authService.resetFreshWithTempPassword(user.id);
  }
  onRemoveUserClick(user) {
    this.usersService.removeUser(user.id);
  }
  setAlsoStudent(user) {
    this.usersService.setUsersSettings(user.id, null)

  }
  copyStudentAppId(user) {
    console.log("🚀 ~ file: users-list.component.ts:113 ~ UsersListComponent ~ copyStudentAppId ~ user:", user)
    //copy to the clipboard user.studentAppId or uder.customerAppId if studentAppId is not set
    let id = user.id
    navigator.clipboard.writeText(id);
    alert("Skopiowano do schowka: " + id)
  }
  edit(i) {
    this.editMode = true;
    this.index = i;
  }

  onUserEditFinished(user) {
    this.editMode = false;
    // alert("dont do this!");
    this.usersService.updateUser(user);
  }

  ngOnDestroy(): void {
    this.usersSub.unsubscribe();
  }
}
