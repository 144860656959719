<div style="height: 100vh" class="d-scrollbars">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #drawer
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'over'"
      [opened]="false"
      class="sidenav"
      fixedInViewport
    >
      <mat-toolbar>Menu</mat-toolbar>
      <mat-divider></mat-divider>
      <mat-nav-list focusOnOpen="false">
        <menu-group-item
          (click)="firstMenuGroupExpanded = !firstMenuGroupExpanded"
          icon="storage"
          text="Database"
        ></menu-group-item>
        <div *ngIf="firstMenuGroupExpanded" class="sub-menu-group">
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['sentences']"
            routerLinkActive="router-link-active"
            text="Sentences"
          ></app-menu-sub-item>
          <app-menu-sub-item
            (click)="drawer.toggle()"
            [routerLink]="['files']"
            routerLinkActive="router-link-active"
            text="Files"
          ></app-menu-sub-item>
          <app-menu-sub-item
            (click)="drawer.toggle()"
            [routerLink]="['facts']"
            routerLinkActive="router-link-active"
            text="Facts"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['audio-repo']"
            routerLinkActive="router-link-active"
            text="Audios"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['video-repo']"
            routerLinkActive="router-link-active"
            text="Videos"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['sets/setslist']"
            routerLinkActive="router-link-active"
            text="Sets"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['books']"
            routerLinkActive="router-link-active"
            text="Books"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['finder']"
            routerLinkActive="router-link-active"
            text="Finder"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="userDetailsService.getSimpleUserDetails().role !== 'teacher'"
            (click)="drawer.toggle()"
            [routerLink]="['manipulator']"
            routerLinkActive="router-link-active"
            text="Manipulator"
          ></app-menu-sub-item>
          <app-menu-sub-item
            *ngIf="
              userDetailsService.getSimpleUserDetails()?.role !== 'teacher'
            "
            (click)="drawer.toggle()"
            [routerLink]="['pictures']"
            routerLinkActive="router-link-active"
            text="Pictures"
          ></app-menu-sub-item>
        </div>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['customers']"
          icon="people_alt"
          text="Customers"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['users']"
          icon="people"
          text="Users"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['office/signup']"
          icon="group_add"
          text="Signup"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['contact-requests']"
          icon="group_add"
          text="Contact requests"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['courses']"
          icon="school"
          text="Courses"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['office/events']"
          icon="event"
          text="Events"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['lessons']"
          icon="lightbulb_outline"
          text="Lessons"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['customers/students']"
          icon="people_outline"
          text="Students"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['customers/groups']"
          icon="group"
          text="Groups"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['schedule']"
          icon="calendar_month"
          text="Schedule"
        ></menu-group-item>
        <menu-group-item
          [routerLink]="['chat']"
          icon="chat"
          text="Chat"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['video-data-creator']"
          icon="subscriptions"
          text="Video data"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.getSimpleUserDetails()?.role !== 'teacher'"
          [routerLink]="['make-video/create']"
          icon="video_call"
          text="Create video"
        ></menu-group-item>
        <menu-group-item
          *ngIf="userDetailsService.owner"
          (click)="openFinanceStats()"
          icon="attach_money"
          text="Stats"
        ></menu-group-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar
        *ngIf="
          userDetails &&
          (userDetails?.role === 'owner' ||
            userDetails?.role === 'agusia' ||
            userDetails?.role === 'office' ||
            userDetails?.role ===
              ('customer' && userDetails?.userData?.alsoStudent) ||
            userDetails?.role === 'teacher' ||
            userDetails?.role === 'admin') &&
          showToolbar === true
        "
        color="primary"
      >
        <button
          (click)="drawer.toggle()"
          *ngIf="
            userDetails?.role === 'owner' ||
            userDetails?.role === 'agusia' ||
            userDetails?.role === 'office' ||
            userDetails?.role === 'admin' ||
            userDetails?.role === 'teacher'
          "
          aria-label="Toggle sidenav"
          mat-icon-button
          type="button"
        >
          <mat-icon aria-label="Side nav toggle icon">more_vert</mat-icon>
        </button>
        <button
          [routerLink]="['']"
          *ngIf="teacherMainListService.showArrow"
          mat-button
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="userDetailsService.getSimpleUserDetails().role === 'owner'"
          class="pointer"
          [routerLink]="['tasks']"
        >
          <mat-icon>task</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="
            userDetailsService.getSimpleUserDetails().role === 'owner' &&
            userDetailsService.getSimpleUserDetails().role !== 'teacher'
          "
          class="pointer"
          [routerLink]="['customers/groups']"
        >
          <mat-icon>groups</mat-icon>
        </button>
        <!-- <span [routerLink]="['']" class="ml-3" style="cursor: pointer;">Ling King {{componentName}}</span> -->
        <span class="pointer" [routerLink]="['']"
          >{{ userDetails?.name }}
        </span>
        <span class="example-spacer"></span>
        <button (click)="logout()" mat-icon-button>
          <mat-icon>power_settings_new</mat-icon>
        </button>
      </mat-toolbar>
      <router-outlet></router-outlet>
      <div></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- <span
    *ngIf="
      userDetails?.role === 'owner' ||
      userDetails?.role === 'agusia' ||
      userDetails?.role === 'office' ||
      userDetails?.role === 'teacher' ||
      userDetails?.role === 'admin'
    "
    >{{ versionService.version }}</span
  > -->
</div>
