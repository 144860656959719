import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinancesStatsService {
  teachersCosts = [
    { name: 'Adrianna Butka', value: 45 },
    { name: 'Wiktoria Zakrzewska', value: 55 },
    { name: 'Weronika Oczki', value: 49 },
    { name: 'Joanna Staniszewska', value: 49 },
    { name: 'Agnieszka Jacek', value: 0 },
    { name: 'Krzysztof Jacek', value: 0 },
    { name: 'Karolina Frasz', value: 61.5 },
    { name: 'Nicole Hojnowska', value: 45 },
    { name: 'Paulina Galant', value: 40 },
    { name: 'Anna Pokorska', value: 40 },
  ]
  financesData = {
    incomes: [
      { name: 'Cykliczne', value: 42000, type: 'monthly', company: true },
      { name: 'zasiłki', value: 3200, type: 'monthly', company: false },

    ],
    expenses: [
      { name: 'Sekretarka', value: 4000, type: 'monthly', company: true },
      { name: 'Lokal Szwederowo', value: 2600, type: 'monthly', company: true },
      { name: 'Lokal', value: 2150, type: 'monthly', company: true },
      { name: 'ZUS', value: 1400, type: 'monthly', company: true },
      { name: 'Podatek', value: 3400, type: 'monthly', company: true },
      { name: 'Rachunki', value: 500, type: 'monthly', company: true },
      { name: 'Leasing', value: 2300, type: 'monthly', company: false },
      { name: 'Paliwo', value: 350, type: 'monthly', company: false },
      { name: 'Telefony', value: 250, type: 'monthly', company: true },
      { name: 'Śmieci', value: 200, type: 'monthly', company: true },
      { name: 'Szkolenie lektorek', value: 350, type: 'monthly', company: true },
      { name: 'Księgowość', value: 500, type: 'monthly', company: true },
      { name: 'Aplikacja', value: 1000, type: 'monthly', company: true },
      { name: 'Inwestycje/Naprawy', value: 600, type: 'monthly', company: true },
      { name: 'Zdrowie', value: 600, type: 'monthly', company: true },
      { name: 'Koszty domowe', value: 5000, type: 'monthly', company: false },
      { name: 'Inne wydatki', value: 2000, type: 'monthly', company: false },





    ],

    states: [
      { name: 'Konto', value: 0 },
      { name: 'Gotówka', value: 0 },
      { name: 'Niezapłacone', value: 0 },
      { name: 'Strata', value: 0 },

    ],

  }


  constructor() {

  }
}
