import { AddEventTimeDialogComponent } from './add-event-time-dialog/add-event-time-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatCalendar,
  MatCalendarCellCssClasses,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { Events2Service } from '../../shared/services/events2.service';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css'],
})
export class BookingFormComponent implements OnInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  avalibleDays: any = [];
  form;
  formSubmitted = false;
  selectedDate = null;
  activeDate = new Date(2023, 8, 1); // Sets the calendar to August 2023

  @Input() newEvent: boolean = true;
  @Input() user: 'office' | 'customer' = 'customer';
  @Input() eventData: any;
  showCalendar = false;
  CustomerDataToSend = {
    event: null,
    customerData: null,
  };
  eventName = '';
  currentTab = 0;
  tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
  inMonth = new Date(Date.now() + 720 * 60 * 60 * 1000);
  excludedDates: Array<any> = [
    // {
    //   date: new Date(Date.now() + 72 * 60 * 60 * 1000),
    //   type: 'busy',
    // },
    // { date: new Date(Date.now() + 96 * 60 * 60 * 1000), type: 'disabled' },
  ];
  currentDay: any;

  constructor(
    private emailService: EmailService,
    private ref: ChangeDetectorRef,
    private helpers: HelpersService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    private eventsService: Events2Service,
  ) {
    this.form = this.fb.group({
      eventData: [null, Validators.required],
      customerName: [null, Validators.required],
      customerSurname: [null, Validators.required],
      customerEmail: [null, [Validators.required, Validators.email]],
      customerPhone: [null, Validators.required],
      termsAccepted: [null, Validators.requiredTrue],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventData) {
      setTimeout(() => {
        this.showCalendar = true;

        this.eventName = changes?.eventData?.currentValue?.name
          ? changes.eventData.currentValue.name
          : '';
        this.avalibleDays = changes?.eventData?.currentValue?.avalibleDays
          ? changes.eventData.currentValue.avalibleDays
          : [];
        this.excludedDates = changes?.eventData?.currentValue?.avalibleDays.map(
          (day) => {
            if (day.avalibleHours.includes('locked')) {
              return day;
            } else {
              return;
            }
          },
        );
        this.excludedDates = this.excludedDates?.filter((day) => day);
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.showCalendar = false;
    this.eventData;
    console.log('-> this.eventData', this.eventData);
    setTimeout(() => {
      setTimeout(() => {
        this.dateClass();
      }, 3000);
    }, 1000);
    this.spinnerService.hide(); //
  }

  onSubmit() {
    const customerData = this.form.value;
    this.form.controls.eventData.setValue(this.CustomerDataToSend.event);
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.formSubmitted = true;
      this.currentTab = 3;
      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 80 ~ BookingFormComponent ~ onSubmit ~ customerData',
        customerData,
      );
      this.eventsService.updateLessonStatus(
        this.eventData.id,
        this.form.value.eventData.dayId,
        this.form.value.eventData.id,
        'busy',
        customerData,
      );
      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 83 ~ BookingFormComponent ~ onSubmit ~ this.form',
        this.form.value,
      ); // todo add customer data
      this.emailService.sendEmail(this.form.value);
    }
    this.updateCalendarView();
  }

  eventSelected(event) {
    if (event.state === 'free' && this.user !== 'office') {
      // this.avalibleHours.splice(this.avalibleHours.indexOf(event), 1) //TODO remove this from database
      this.CustomerDataToSend.event = event;
      event.dayDate = this.currentDay.date;
      event.dayId = this.currentDay.id;
      this.currentTab = 2;
    }
    this.updateCalendarView();
  }

  addEventTime() {
    const dialogRef = this.dialog.open(AddEventTimeDialogComponent);

    dialogRef.afterClosed().subscribe((eventTime) => {
      eventTime.id = this.helpers.makeid();
      eventTime.name = eventTime?.name;
      eventTime.type = eventTime?.type;
      eventTime.state = 'free';
      eventTime.description = eventTime?.description;
      eventTime.endDateObj.setDate(this.selectedDate.getDate());
      eventTime.endDateObj.setMonth(this.selectedDate.getMonth());
      eventTime.endDateObj.setFullYear(this.selectedDate.getFullYear());
      eventTime.startDateObj.setDate(this.selectedDate.getDate());
      eventTime.startDateObj.setMonth(this.selectedDate.getMonth());
      eventTime.startDateObj.setFullYear(this.selectedDate.getFullYear());
      eventTime.date =
        this.selectedDate.getDate().toString().padStart(2, '0') +
        '.' +
        (this.selectedDate.getMonth() + 1).toString().padStart(2, '0') +
        '.' +
        this.selectedDate.getFullYear();
      eventTime.eventDateObj = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
      );
      const dayPresent = this.avalibleDays.some(
        (day) => day.date.toString() == eventTime.eventDateObj.toString(),
      );
      const dateForDay = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
      );
      this.currentDay = this.avalibleDays.find(
        (day) => day.date == eventTime.eventDateObj,
      );

      if (!dayPresent) {
        this.avalibleDays.push({
          id: this.helpers.makeid(),
          date: dateForDay.toString(),
          avalibleHours: [],
        });
        this.currentDay = this.avalibleDays.find(
          (day) => day.date == eventTime.eventDateObj,
        );
        this.currentDay.avalibleHours.push(eventTime);
      } else {
        this.currentDay.avalibleHours.push(eventTime);
        this.currentDay.avalibleHours = this.helpers.sortArrayByProperty(
          this.currentDay.avalibleHours,
          'startDateObj',
          'asc',
        );
      }
    });
    this.updateCalendarView();
  }

  dateSelected(event) {
    this.selectedDate = event;
    const eventDateObj = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate(),
    );
    this.currentDay = this.avalibleDays.find((day) => day.date == eventDateObj);
    if (this.currentDay) {
      this.currentDay.avalibleHours = this.helpers.sortArrayByProperty(
        this.currentDay.avalibleHours,
        'startDateObj',
        'asc',
      );
    } else {
    }

    this.currentTab = 1;
  }

  sendEvent() {
    if (this.newEvent) {
      this.eventsService.saveNewEvent({
        name: this.eventName,
        avalibleDays: this.avalibleDays,
        added: Date.now(),
        lastModified: Date.now(),
      });
    } else {
      //todo make update event and update button text save/update
      this.eventsService.updateEvent(this.eventData.id, {
        name: this.eventName,
        added: this.eventData.added,
        avalibleDays: this.avalibleDays,
        lastModified: Date.now(),
      });
    }
  }

  lockDay() {
    const eventDateObj = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate(),
    );
    this.currentDay = this.avalibleDays.find((day) => day.date == eventDateObj);
    if (this.currentDay) {
      this.currentDay.avalibleHours = [];
      this.currentDay.avalibleHours.push('locked');
      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 153 ~ BookingFormComponent ~ lockDay ~ this.currentDay.avalibleHours',
        this.currentDay.avalibleHours,
      );
    } else {
      const eventDateObj = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
      );
      this.avalibleDays.push({
        id: this.helpers.makeid(),
        date: eventDateObj.toString(),
        avalibleHours: [],
      });
      this.currentDay = this.avalibleDays.find(
        (day) => day.date == eventDateObj,
      );
      this.currentDay.avalibleHours.push('locked');

      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 153 ~ BookingFormComponent ~ lockDay ~ this.currentDay.avalibleHours',
        this.currentDay.avalibleHours,
      );
      this.sendEvent();
      this.setCurrentTab(0);
      this.updateCalendarView();
      this.ref.detectChanges();
    }
  }

  markAsAvailableDay() {
    const eventDateObj = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      this.selectedDate.getDate(),
    );
    this.currentDay = this.avalibleDays.find((day) => day.date == eventDateObj);
    if (this.currentDay) {
      this.currentDay.avalibleHours = [];
      this.currentDay.avalibleHours.push('avalible');
      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 153 ~ BookingFormComponent ~ lockDay ~ this.currentDay.avalibleHours',
        this.currentDay.avalibleHours,
      );
    } else {
      const eventDateObj = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        this.selectedDate.getDate(),
      );
      this.avalibleDays.push({
        id: this.helpers.makeid(),
        date: eventDateObj.toString(),
        avalibleHours: [],
      });
      this.currentDay = this.avalibleDays.find(
        (day) => day.date == eventDateObj,
      );
      this.currentDay.avalibleHours.push('avalible');

      console.log(
        '🚀 ~ file: booking-form.component.ts ~ line 153 ~ BookingFormComponent ~ lockDay ~ this.currentDay.avalibleHours',
        this.currentDay.avalibleHours,
      );
      this.sendEvent();
      this.setCurrentTab(0);
      this.updateCalendarView();
      this.ref.detectChanges();
    }
  }

  updateCalendarView() {
    this.showCalendar = false;
    setTimeout(() => {
      this.showCalendar = true;
      setTimeout(() => {
        this.activeDate = this.avalibleDays[this.avalibleDays.length - 1].date;
        if (!(this.activeDate instanceof Date)) {
          this.activeDate = new Date(this.activeDate);
        }
        this.activeDate.setMonth(this.activeDate.getMonth());
      }, 1000);
    }, 1);
  }

  markAsBusy(event) {
    if (event.state === 'free') {
      event.state = 'busy';
    } else {
      event.state = 'free';
      event.customerData = null;
    }
    this.updateCalendarView();
  }

  setCurrentTab(event) {
    this.currentTab = event;
  }

  removeEvent(event) {
    if (event !== 'locked') {
      this.currentDay.avalibleHours = this.helpers.removeElementFromArray(
        this.currentDay.avalibleHours,
        event,
      );
      this.currentDay.avalibleHours = this.helpers.sortArrayByProperty(
        this.currentDay.avalibleHours,
        'startDateObj',
        'asc',
      );
    } else {
      this.currentDay.avalibleHours = [];
    }
    if (this.currentDay.avalibleHours.length === 0) {
      this.avalibleDays = this.avalibleDays.filter(
        (day) => day !== this.currentDay,
      );
    }
    this.setCurrentTab(0);
    this.sendEvent();
    this.updateCalendarView();
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (this.user === 'customer') {
        if (
          this.filterDates(date) &&
          date >= this.tomorrow &&
          this.avalibleDays.some(
            (day) =>
              day.date.includes(date) &&
              !day.avalibleHours.some((hour) => hour.state === 'free'),
          )
        ) {
          return 'date-full';
        }
        if (
          this.filterDates(date) &&
          date >= this.tomorrow &&
          this.avalibleDays.some((day) => day.date.includes(date))
        ) {
          return 'date-avalible';
        }
        if (
          !this.filterDates(date) &&
          date > this.tomorrow &&
          this.excludedDates.some((excludedDate) => excludedDate.date == date)
        ) {
          return 'date-unavailable';
        } else {
          return;
        }
      }

      if (this.user === 'office') {
        if (
          this.filterDates(date) &&
          this.avalibleDays.some(
            (day) =>
              day.date.includes(date) &&
              !day.avalibleHours.some((hour) => hour.state === 'free'),
          )
        ) {
          return 'date-full';
        }
        if (
          this.filterDates(date) &&
          this.avalibleDays.some((day) =>
            day.date
              .replace(/ *\([^)]*\) */g, '')
              .includes(date.toString().replace(/ *\([^)]*\) */g, '')),
          )
        ) {
          return 'date-avalible';
        }
        if (
          !this.filterDates(date) &&
          this.excludedDates.some((excludedDate) => excludedDate.date == date)
        ) {
          return 'date-unavailable';
        } else {
          return;
        }
      }
    };
  }

  filterDates = (date: Date): boolean => {
    const dateExcluded = this.excludedDates?.some(
      (excludedDate) =>
        excludedDate?.date == date ||
        !this.avalibleDays.some((day) => day.date == date)!,
    );
    if (!dateExcluded) {
      return true;
    } else {
      return false;
    }
  };
}
